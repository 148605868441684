<template>
  <li class="flex py-2 px-6">
    <Checkbox :binary="true" v-model="completed"></Checkbox>
    <div class="ml-4">
      <h6 v-if="reminder.link" class="m-0 text-color" :class="{ 'line-through': !!reminder.date_completed }">
        <NuxtLink :to="reminder.link">{{reminder.full_title }}</NuxtLink>
      </h6>
      <h6 v-else class="m-0 text-color" :class="{ 'line-through': !!reminder.date_completed }">
        {{ reminder.full_title }}
      </h6>
      <span class="block text-sm text-color opacity-50">
        {{ formatDate(reminder.date, 'PPp') }}
        <span> - </span>
        <a
          href="#"
          @click="() => dialog.open(DialogReminderAddChange, {
            props: {
              breakpoints: {
                '960px': '75vw',
                '640px': '90vw'
              },
              header: 'Edit reminder',
              modal: true,
              style: {
                width: '30vw'
              }
            },
            data: {
              reminder: reminder
            }
          })"
        >
          Edit
        </a>
      </span>

    </div>
  </li>
</template>

<script lang="ts" setup>
import DialogReminderAddChange from "~/components/dialogs/DialogReminderAddChange.vue";

const dialog = useDialog()
const loading = ref<boolean>(false)
const props = defineProps<{
  reminder: Reminder
}>()
const reminder = toRef(props, 'reminder')

const completed = computed<boolean>({
  get() {
    return !!reminder.value.date_completed
  },
  set(value) {
    updateComplete(value)
  }
})

const updateComplete = async (value: boolean) => {
  loading.value = true

  const {$api} = useNuxtApp()
  await $api<Reminder>(`/reminders/${reminder.value.id}/`, {
    method: 'PATCH',
    body: JSON.stringify({
      date_completed: value ? new Date() : null
    })
  })
    .then(response => {
      reminder.value.date_completed = response.date_completed
    })
    .finally(() => loading.value = false)
}
</script>

